import './Hobbies.css'

const Hobbies = ()  => {
    return (
        <div className='hobbiesSection'>
            <h4 className='hobbiesSectionTitle'>My Hobbies</h4>
            <div className='hobbiesCard'>
            <img src = { require('./imagesAbout/ArtIcon.png')} alt = "main" className="hobbiesIconIma"></img>
                <div className='hobbiesCardParagrafSection'>
                    <h1 className='hobbiesCardParagrafTitle'>Art</h1>
                    <p className='hobbiesCardParagrafSecParag'>Art has always been an essential part of my life. 
                    Whether it's sketching in my notebook during my free moments or visiting art galleries, 
                    I find relief in expressing my thoughts and emotions through art. 
                    I would love to learn more about the art and express myself in the field.</p>
                </div>
            </div>

            <div className='hobbiesCard'>
            <img src = { require('./imagesAbout/FashionIcon.png')} alt = "main" className="hobbiesIconIma"></img>
                <div className='hobbiesCardParagrafSection'>
                    <h1 className='hobbiesCardParagrafTitle'>Fashion Design</h1>
                    <p className='hobbiesCardParagrafSecParag'> I recently discovered the world of fashion design. 
                    Wanting to learn more about design, I decided to start my journey in fashion design. 
                    I look forward to weaving my passion for fashion design into my computer science background and creating a tapestry of innovation that intertwines these two seemingly disparate worlds.</p>
                </div>
            </div>

            <div className='hobbiesCard'>
            <img src = { require('./imagesAbout/PoolIcon.png')} alt = "main" className="hobbiesIconIma"></img>
                <div className='hobbiesCardParagrafSection'>
                    <h1 className='hobbiesCardParagrafTitle'>Pool</h1>
                    <p className='hobbiesCardParagrafSecParag'> Diving into the world of pool games has been an exhilarating and rewarding experience for me.
                     Whether it's a casual game with friends or a more competitive setting, it helps me unwind and relax. 
                    It's a delightful  hobby that allows me to connect with others, and embrace the joy of friendly competition.</p>
                </div>
            </div>

            <div className='hobbiesCard'>
            <img src = { require('./imagesAbout/HorseRidingIcon.png')} alt = "main" className="hobbiesIconIma"></img>
                <div className='hobbiesCardParagrafSection'>
                    <h1 className='hobbiesCardParagrafTitle'>Horse Riding</h1>
                    <p className='hobbiesCardParagrafSecParag'>Horse riding is a passion that has ignited a profound connection between me and these majestic creatures. 
                    The rhythmic gallop, the gentle sway of the saddle, and the harmony between horse and rider create a sense of freedom and unity unlike any other. 
                    Beyond riding, horseback adventures have also granted me a deep appreciation for nature and the outdoors. </p>
                </div>
            </div>

            <div className='hobbiesCard'>
            <img src = { require('./imagesAbout/SallingIcon.png')} alt = "main" className="hobbiesIconIma"></img>
                <div className='hobbiesCardParagrafSection'>
                    <h1 className='hobbiesCardParagrafTitle'>Sailing</h1>
                    <p className='hobbiesCardParagrafSecParag'> Sailing is my ultimate escape into a world of boundless horizons and the soothing embrace of the open waters.
                     Guiding the sailboat as it dances gracefully with the wind, I feel an unparalleled sense of serenity and adventure. 
                    The art of harnessing the elements to navigate through the waves unlocks a profound connection with nature and the sea.</p>
                </div>
            </div>
        </div>

    );
    
}


export default Hobbies;